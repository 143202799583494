.header,
.main,
.footer {
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/contain */
  contain: content;
}

.title {
  font-size: var(--h4-font-size);
  font-weight: bold;
  margin-right: auto;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 1rem 0.5rem;
  overscroll-behavior: contain;
  /* From https://material.io/design/motion/speed.html */
  /* Exit is faster, deceleration easing */
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(-100%);
}

.navOpen {
  background-color: var(--background-color);
  /* Enter takes longer, acceleration easing */
  transition: transform 300ms cubic-bezier(0.4, 0, 1, 1);
  transform: translateX(0);
}

.main {
  padding: 0 1rem;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .aside {
    contain: content;
  }

  .nav {
    position: sticky;
    transform: none;
    width: 15rem;
  }

  .menu {
    display: none;
  }
}
