.p,
.pBold,
.pLight {
  white-space: pre-wrap;
  word-break: break-all;
}

.pBold {
  font-weight: bold;
}

.pLight {
  font-weight: lighter;
}
