.nav-link {
  display: block;
  padding: 0.5rem 0.75rem;
  color: var(--gray-700);
}

.nav-link-active {
  color: var(--gray-800);
  font-weight: bold;
}
