.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@supports (display: grid) {
  .layout {
    display: grid;
    grid-template-areas:
      'header header header'
      'aside main main'
      'aside footer footer';
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
  }

  .header {
    grid-area: header;
  }

  .aside {
    grid-area: aside;
  }

  .main {
    grid-area: main;
  }

  .footer {
    grid-area: footer;
  }
}
