:global(.dark-mode) .navbar {
  box-shadow: 0 2px 8px var(--gray-0);
}

@media (prefers-color-scheme: dark) {
  .navbar {
    box-shadow: 0 2px 8px var(--gray-0);
  }
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px var(--gray-200);
}
