.input {
  display: block;
  font: inherit;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: inherit;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid var(--gray-400);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
