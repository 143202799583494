/**
 * 1. Prevent touch delay on iOS devices
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  touch-action: manipulation; /* 1 */
  -webkit-text-size-adjust: 100%; /* 1 */
}

/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: var(--color);
  background-color: var(--background-color);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color: hsl(0, 0%, 14%);
  --background-color: hsl(0, 0%, 100%);
  --gray-100: hsl(180, 8%, 97%);
  --gray-200: hsl(210, 10%, 92%);
  --gray-300: hsl(200, 8%, 86%);
  --gray-400: hsl(204, 9%, 78%);
  --gray-500: hsl(210, 10%, 57%);
  --gray-600: hsl(209, 9%, 45%);
  --gray-700: hsl(205, 12%, 32%);
  --gray-800: hsl(207, 16%, 22%);
  --gray-900: hsl(210, 12%, 16%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: hsl(0, 0%, 14%);
    --color: hsl(0, 0%, 100%);
    --gray-900: hsl(180, 8%, 97%);
    --gray-800: hsl(210, 10%, 92%);
    --gray-700: hsl(200, 8%, 86%);
    --gray-600: hsl(204, 9%, 78%);
    --gray-500: hsl(210, 10%, 57%);
    --gray-400: hsl(209, 9%, 45%);
    --gray-300: hsl(205, 12%, 32%);
    --gray-200: hsl(207, 16%, 22%);
    --gray-100: hsl(210, 12%, 16%);
    --gray-0: hsl(0, 12%, 8%);
  }
}

.dark-mode {
  --background-color: hsl(0, 0%, 14%);
  --color: hsl(0, 0%, 100%);
  --gray-900: hsl(180, 8%, 97%);
  --gray-800: hsl(210, 10%, 92%);
  --gray-700: hsl(200, 8%, 86%);
  --gray-600: hsl(204, 9%, 78%);
  --gray-500: hsl(210, 10%, 57%);
  --gray-400: hsl(209, 9%, 45%);
  --gray-300: hsl(205, 12%, 32%);
  --gray-200: hsl(207, 16%, 22%);
  --gray-100: hsl(210, 12%, 16%);
  --gray-0: hsl(0, 12%, 8%);
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'DejaVu Sans Mono',
    'Liberation Mono', 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

*,
::before,
::after {
  box-sizing: border-box;
}

a {
  color: var(--blue-500, inherit);
  text-decoration: none;
  background-color: transparent;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
     that do support :focus-visible */
  outline: none;
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  font-size: var(--p-font-size);
}
