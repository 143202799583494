.swatchContainer {
  margin: 0 0 1rem;
}

.swatch {
  border: 1px solid gray;
  border-radius: 3px;
  height: 10rem;
  width: 10rem;
}
