.button {
  --border: var(--gray-400);
  --backgroundHover: var(--gray-100);
  --backgroundActive: var(--gray-200);
  --colorDisabled: var(--gray-600);
  --borderDisabled: var(--gray-300);
  --backgroundDisabled: var(--gray-100);
}

.buttonPrimary {
  --color: white;
  --border: var(--blue-500);
  --background: var(--blue-500);
  --backgroundHover: var(--blue-600);
  --backgroundActive: var(--blue-700);
}

.buttonDanger {
  --color: white;
  --background: var(--red-500);
  --backgroundHover: var(--red-600);
  --backgroundActive: var(--red-700);
  --backgroundDisabled: var(--red-400);
}

.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  text-transform: none;
  overflow: visible;
  color: var(--color);
  background-color: var(--background, --background-color);
  border-color: var(--border);
  -webkit-appearance: button;
}

.button:not(:disabled):not(.buttonDisabled) {
  cursor: pointer;
}

/* Remove focus outline in mozilla */
.button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.button:focus,
.buttonFocus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* Remove the focus indicator on mouse-focus for browsers
   that do support :focus-visible */
.button:focus:not(:focus-visible) {
  outline: none;
}

@media (hover: hover) {
  .button:hover {
    background-color: var(--backgroundHover);
  }
}
.buttonHover {
  background-color: var(--backgroundHover);
}

.button:active,
.button.buttonActive {
  background-color: var(--backgroundActive);
}

.button.buttonDisabled,
.button:disabled {
  color: var(--colorDisabled);
  background-color: var(--backgroundDisabled);
  border-color: var(--borderDisabled);
}

.buttonGroup > .button {
  border-left-width: 0;
  border-radius: 0;
}

.buttonGroup > .button:first-child {
  border-left-width: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.buttonGroup > .button:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
