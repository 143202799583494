.layoutContainer {
  margin: 1.5rem;
  text-align: center;
  color: white;
}

.header,
.aside,
.main,
.footer {
  padding: 1rem;
}

.header {
  background-color: var(--blue-400);
}
.aside {
  background-color: var(--blue-500);
}
.main {
  height: 10rem;
  background-color: var(--blue-600);
}
.footer {
  background-color: var(--blue-400);
}

.asideRight {
  display: grid;
  grid-template-areas:
    'header header header'
    'main main aside'
    'footer footer aside';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
}

.asideFirst {
  display: grid;
  grid-template-areas:
    'aside header header'
    'aside main main'
    'aside footer footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
}
