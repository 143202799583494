.list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.listCol {
  flex-direction: column;
}
